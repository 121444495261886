import { useEffect, useMemo, useRef, useState } from "preact/hooks";
import { eventHandler } from "../../utils/eventHandler";
import focus from "../../utils/focus";
import { getValueOf } from "../../utils/gridUtils";
import { KEYS } from "../../utils/keys";
import Item from "./Item";
import { isElementVisible, moveFocus } from "../../utils/movement";
import Button from "../../components/common/button";
import VerticalCenter from "../../components/common/verticalCenter";
import { useSelector } from "react-redux";
import { GENDER, MOVIES } from "../../utils/constants";
import { Texts } from "../../utils/texts";
import {
    addHiddenLegend,
    isLastElementOfGroup,
    removeHiddenLegend,
    renderHiddenLegendToRow,
    SCROLL_ITEMS_DIV,
    scrollItemsDown,
} from "../../utils/movies";
import { memo } from "preact/compat";
import { figmaHeightPx2Px, figmapx2vh, figmapx2vw } from "../../utils/utils";
import { setLanding } from "../../actions/uiActions";

const MAX_ITEMS_SHOWN = 15;

const Groups = ({
    category,
    itemHeight,
    setActiveSingleGroup,
    forceFocus,
    itemDetail,
    itemsPerRow = 8,
    forceRefresh,
}) => {
    const texts = useSelector((state) => state.ui.texts);
    const TOP_OFFSET = figmaHeightPx2Px(itemHeight);
    const LEFT_OFFSET = (window.innerWidth / 100) * 90;
    const HORIZONTAL_STEP = (window.innerWidth / 100) * 15;
    const GROUP_HEIGHT = itemHeight ? figmapx2vh(itemHeight + 55) : figmapx2vh(360); // default for movies

    const blockMovement = useRef(false);
    const singleGroup = category?.items?.length === 1;

    if (!category) {
        return null;
    }

    const getGroupFocused = () => {
        let groupId = 0;
        if (document.activeElement.id.indexOf("showAll") > -1) {
            groupId = parseInt(document.activeElement.getAttribute("customattribute"));
        } else {
            groupId = parseInt(focus?.value?.current?.split("-")?.[1]?.split("_")[0]);
        }
        const previous = groupId !== 0 ? category.items[groupId - 1] : false;
        const next = groupId < category.items.length - 1 ? category.items[groupId + 1] : false;
        const _group = category.items[groupId];
        return { ..._group, next, previous };
    };

    const focusMovie = (movie) => {
        setLanding(true);
        focus.value.replace(movie);
        setTimeout(() => {
            scrollGroupLeftTo(movie);
        }, 100);
    };

    const hideGroup = (groupname, focusItem) => {
        document.getElementById(`Parent-group-${groupname}`).style.height = "0px";
        document.getElementById(`group-${groupname}-items`).style.height = "0px";

        document.getElementById(`group-${groupname}-items`).style.marginLeft = "0px";
        setTimeout(() => {
            document.getElementById(`group-${groupname}-name`).style.display = "none";
            document.getElementById(`Parent-group-${groupname}`).style.display = "none";
            if (focusItem) {
                focusMovie(focusItem);
            }
        }, 30);
    };
    const showGroup = (group, lastGroup) => {
        document.getElementById(`Parent-group-${group.name}`).style.display = "block";
        setTimeout(() => {
            document.getElementById(`Parent-group-${group.name}`).style.height = GROUP_HEIGHT;
            document.getElementById(`group-${group.name}-items`).style.height = itemHeight + "px";
            document.getElementById(`group-${group.name}-name`).style.display = "block";
            setTimeout(() => {
                const focusItemUp = moveFocus(focus.value.current, "up");
                focusMovie(focusItemUp);
                document.getElementById(`group-${lastGroup}-items`).style.marginLeft = "0px";
            }, 50);
        }, 30);
    };

    const scrollGroupLeftTo = (itemId) => {
        if (category.items.length === 1) {
            return;
        }
        const _grpF = getGroupFocused();
        if (_grpF.name) {
            const actualScroll = getValueOf(document.getElementById(`group-${_grpF.name}-items`).style.marginLeft);
            let moveLeft = 0;
            if (window.innerWidth - document.getElementById(itemId).getBoundingClientRect().right < 0) {
                moveLeft = document.getElementById(itemId).getBoundingClientRect().right - LEFT_OFFSET;
            }
            document.getElementById(`group-${_grpF.name}-items`).style.marginLeft = actualScroll - moveLeft + "px";
            blockMovement.current = false;
        }
    };

    const onGroupsKeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;

        const groupFocused = getGroupFocused();

        if (keyData) {
            switch (keyData.value) {
                case KEYS.back:
                    break;
                case KEYS.down:
                    // prevent moving to fast and losing focus
                    if (blockMovement.current) {
                        eventHandler.stopPropagation(e);
                        break;
                    }
                    if (groupFocused.next) {
                        blockMovement.current = true;
                        const nextMovieDown = moveFocus(document.activeElement.id, "down");
                        hideGroup(groupFocused.name, nextMovieDown);
                        preventDefault = true;
                    }
                    break;
                case KEYS.up:
                    // prevent moving to fast and losing focus
                    if (blockMovement.current) {
                        eventHandler.stopPropagation(e);
                        break;
                    }
                    if (groupFocused.previous) {
                        blockMovement.current = true;
                        showGroup(groupFocused.previous, groupFocused.name);

                        preventDefault = true;
                    }
                    break;
                case KEYS.right:
                    // prevent moving to fast and losing focus
                    if (blockMovement.current) {
                        eventHandler.stopPropagation(e);
                        break;
                    }
                    if (document.activeElement.nextElementSibling) {
                        blockMovement.current = true;
                        focusMovie(document.activeElement.nextElementSibling.id);
                    }
                    preventDefault = true;
                    break;
                case KEYS.left:
                    if (!document.activeElement.previousElementSibling) {
                        preventDefault = true;
                    } else {
                        if (!isElementVisible(document.activeElement.previousElementSibling.previousElementSibling)) {
                            const actualScroll = getValueOf(
                                document.getElementById(`group-${groupFocused.name}-items`).style.marginLeft,
                            );
                            const moveRight =
                                HORIZONTAL_STEP -
                                document.activeElement.previousElementSibling.getBoundingClientRect().left;
                            const newScroll = actualScroll + moveRight > 0 ? 0 : actualScroll + moveRight;
                            document.getElementById(`group-${groupFocused.name}-items`).style.marginLeft =
                                newScroll + "px";
                        }
                    }
                    break;
                default:
                    break;
            }
        }

        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    const onGroupsSingleKeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;

        if (keyData) {
            switch (keyData.value) {
                case KEYS.left:
                    const posFocus = category.items[0].items.findIndex((item) => item.focusId === focus.value.current);
                    if (posFocus % itemsPerRow === itemsPerRow - 1) {
                        // addHiddenLegend(category.items[0].items?.[posFocus]?.focusId);
                    }
                    break;
                case KEYS.up:
                    const nextItemUp = moveFocus(document.activeElement.id, "up");
                    scrollItemsDown(false, TOP_OFFSET);
                    break;
                case KEYS.down:
                    const nextItemDown = moveFocus(document.activeElement.id, "down");
                    if (
                        document.activeElement.id !== "btn-exit" &&
                        nextItemDown &&
                        !isElementVisible(document.getElementById(nextItemDown))
                    ) {
                        scrollItemsDown(true, TOP_OFFSET);
                    }
                    break;
                case KEYS.right:
                    if (isLastElementOfGroup(category.items[0])) {
                        preventDefault = true;
                    }
                    break;
                default:
                    break;
            }
        }

        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    useEffect(() => {
        if (forceFocus) {
            console.log(forceFocus);
            category.items.forEach((_group) => {
                if (forceFocus.groupId > _group.id) {
                    hideGroup(_group.name);
                }
            });
            focusMovie(forceFocus.elementId);
        }
    }, [forceFocus]);

    useEffect(() => {
        if (category.items.length === 1) {
            renderHiddenLegendToRow(category.items[0], itemsPerRow);
        }
        if (document.activeElement.getAttribute("customattribute") === "category") {
            removeHiddenLegend();
        }
    }, [focus.value.current]);

    const noCategoryYet = () => {
        return (
            <div className={"mt-8 text-center"}>
                {Texts.translateCap(texts, "no-category-yet").replace("{{categoryName}}", category.name.toLowerCase())}
            </div>
        );
    };

    return useMemo(() => {
        return category.items.length === 0 ? (
            noCategoryYet()
        ) : category.items.length === 1 ? (
            <>
                {category.items[0].items.length > 0 ? (
                    <div
                        id={"groupItems"}
                        className={"overflow-hidden"}
                        style={{
                            height: figmapx2vh(880),
                            marginLeft: figmapx2vw(72),
                        }}
                        onKeyDown={(e) => {
                            onGroupsSingleKeydown(e);
                        }}
                    >
                        <div id={SCROLL_ITEMS_DIV} className={"animateMarginTop"}>
                            {category.items[0].items.map((item) => (
                                <Item
                                    element={item}
                                    singleGroup={true}
                                    detail={itemDetail}
                                    noRentedRibbon={category.id === MOVIES.FILTERS.RENTED}
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    noCategoryYet()
                )}
            </>
        ) : (
            <div
                id={`groupList-${category.name}`}
                className={"mt-8"}
                style={{
                    marginLeft: figmapx2vw(72),
                }}
                onKeyDown={(e) => {
                    onGroupsKeydown(e);
                }}
            >
                {category.items.map((group) => {
                    if (group.items.length === 0) {
                        return null;
                    }
                    return (
                        <>
                            <div
                                id={`Parent-group-${group.name}`}
                                className={`text-gray-100 font-700 inline-block  horizontalGroup gridGroupAnimation`}
                                style={{ fontSize: figmapx2vw(36), height: GROUP_HEIGHT, minWidth: "100vw" }}
                            >
                                <div id={`group-${group.name}-name`}>{group.name}</div>
                                <div
                                    id={`group-${group.name}-items`}
                                    className={"mt-4 gridGroupAnimation"}
                                    style={{
                                        width: !singleGroup ? "max-content" : "100vw",
                                        overflow: "hidden",
                                        height: figmapx2vh(itemHeight),
                                    }}
                                >
                                    {group.items.map((item, index) => {
                                        if (index === MAX_ITEMS_SHOWN) {
                                            return (
                                                <Button
                                                    key={`${group.name}-showAll`}
                                                    data={{
                                                        id: `${group.name}-showAll`,
                                                        customClass: `left-bounding ${
                                                            index < group.items.length - 1 && "float-left"
                                                        }`,
                                                        customAttribute: group.id,
                                                        bodyClass: !singleGroup ? "inline-flex" : null,
                                                        focusClass: "channel-focus",
                                                        customStyle: {
                                                            width: figmapx2vw(192),
                                                            marginRight: figmapx2vw(24),
                                                            marginBottom: figmapx2vh(0),
                                                            borderRadius: figmapx2vw(4),
                                                        },
                                                        removeButtonClass: true,
                                                        onClick() {
                                                            setActiveSingleGroup(group, {
                                                                groupId: group.id,
                                                                elementId: document.activeElement.id,
                                                            });
                                                        },
                                                        body: (
                                                            <div
                                                                className={`w-full h-full rounded remark`}
                                                                style={{
                                                                    width: figmapx2vw(192),
                                                                    height: figmapx2vh(288),
                                                                    backgroundColor: "#252626",
                                                                }}
                                                            >
                                                                <VerticalCenter
                                                                    text={
                                                                        <div className={"content"}>
                                                                            <div
                                                                                className=""
                                                                                style={{
                                                                                    fontSize: figmapx2vw(28),
                                                                                    width: figmapx2vw(192),
                                                                                    color: "#F5F6F8",
                                                                                    fontWeight: 400,
                                                                                    paddingLeft: "1vw",
                                                                                    paddingRight: "1vw",
                                                                                }}
                                                                            >
                                                                                {Texts.translateCap(
                                                                                    texts,
                                                                                    item.genderType === GENDER.MALE
                                                                                        ? "Show all"
                                                                                        : "Show all f",
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    cClass={``}
                                                                />
                                                            </div>
                                                        ),
                                                    }}
                                                ></Button>
                                            );
                                        } else if (index > MAX_ITEMS_SHOWN) {
                                            return;
                                        }
                                        return <Item element={item} detail={itemDetail} singleGroup={false} />;
                                    })}
                                </div>
                            </div>
                        </>
                    );
                })}
            </div>
        );
    }, [category, forceRefresh]);
};

export default memo(Groups);
