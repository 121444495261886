import { SUBTITLE_BASE_URL } from "../../../utils/movies";
import { getLanguageNameFromIso, vh2px, vw2px } from "../../../utils/utils";
import { procentric } from "./procentric";

export const Media = {
    init() {
        this.VIDEOPANE = document.createElement("div");
        this.VIDEOPANE.style.position = "absolute";
        this.VIDEOPANE.style.backgroundImage = "url(tv:)";
        this.VIDEOPANE.style.backgroundColor = "blue";
        this.VIDEOPANE.style.zIndex = "50";
        this.VIDEOPANE.style.visibility = "hidden";

        this.VIDEOPANE.id = "regularVideoPane";
        document.body.appendChild(this.VIDEOPANE);
    },
    _stop() {
        procentric.audioList = null;
        procentric.stopChannel();
        procentric.stopAndPlayMedia();
        procentric.mediaPosition = 0;
        this.removeVideoPane();
    },
    addVideoPane: function () {
        if (this.VIDEOPANE) {
            this.VIDEOPANE.style.visibility = "";
        }
    },
    setVideoSize(x, y, width, height) {
        x = vw2px(x);
        y = vh2px(y);
        width = vw2px(width);
        height = vh2px(height);
        procentric.setVideoSize(x, y, width, height);
    },
    _playChannel(ip, port) {
        procentric.playTest(ip, port);
    },
    removeVideoPane() {
        if (this.VIDEOPANE) {
            this.VIDEOPANE.style.visibility = "hidden";
        }
    },
    getAudioList(onSuccess) {
        const onListAction = (list) => {
            if (!list) {
                onSuccess(null);
                return;
            }
            let audioList = [];
            const audios = list.split(",");
            audios.map((audio, index) =>
                audioList.push({ id: index, name: getLanguageNameFromIso(audio), iso: audio }),
            );

            onSuccess(audioList);
        };

        procentric.getAudioList(onListAction);
    },
    getSubtitleList(onSuccess) {
        const onListAction = (list) => {
            if (!list) {
                onSuccess(null);
                return;
            }
            let subtitleList = [];
            const subtitles = list.replace("off,", "").split(",");
            subtitles.map((subtitle, index) =>
                subtitleList.push({ id: index, name: getLanguageNameFromIso(subtitle) }),
            );

            onSuccess(subtitleList);
        };

        procentric.getSubtitleList(onListAction);
    },
    changeAudio(ind) {
        procentric.setCurrentAudio(Number(ind));
    },
    changeSubtitle(ind, movie) {
        if (movie) {
            procentric.setSubtitleOn();
            procentric.subtitleList = [...movie.subtitles];
            const subtitleUrl = `${SUBTITLE_BASE_URL}${movie.subtitles.find((sub) => sub.id === ind).file}`;
            procentric.setSubtitleUrl(subtitleUrl);
        } else {
            procentric.setCurrentSubtitle(Number(ind + 1)); //+1 to be coherent with interface subtitle list
        }
    },
    disableSubtitles() {
        procentric.setSubtitleOff();
        procentric.setCurrentSubtitle(0);
    },
    _playHLSVideo(url, loop) {
        procentric.stopAndPlayMedia(url, loop);
    },
    _playDVBTChannel: function (channel) {
        Media.addVideoPane();
        procentric.changeChannelDVBT(channel.frequency, channel.isc);
    },
    _playDVBCChannel: function (channel) {
        Media.addVideoPane();
        procentric.changeChannelDVBC(channel.frequency, channel.isc);
    },
    _playATSCChannel: function (channel) {
        console.debug("Would play ATSC: " + JSON.stringify(channel));
        Media.addVideoPane();
        procentric.changeChannelATSC(channel.ptc, channel.minor);
    },
    _playDVBSChannel: function (channel) {
        Media.addVideoPane();
        procentric.changeChannelDVBS(channel.frequency, channel.isc, parseInt(channel.satelliteId));
    },
    pause() {
        if (procentric.media) {
            procentric.getMediaState(function (p) {
                console.debug("media state: " + p);
                if (p === "play") {
                    procentric.pauseMedia();
                }
            });
        }
    },
    resume() {
        procentric.resumeMedia();
    },
    getStreamPos() {
        procentric.getMediaPlayPosition(function (ms) {
            procentric.mediaPosition = ms;
        });
        return procentric.mediaPosition / 1000; //TODO return from callback!!!
    },
    setStreamPos(newpos) {
        console.debug("Set stream position to " + newpos);
        procentric.setMediaPlayPosition(newpos * 1000);
        procentric.mediaPosition = newpos * 1000;
    },
    checkForceAudio: function (movie) {
        return false;
    },
};
