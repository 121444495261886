import { useDispatch, useSelector } from "react-redux";
import { InitializePC } from "./pc/initializePC";
import { InitializeProcentric } from "./procentric/initializeProcentric";
import { InitializeTizen } from "./samsung/tizen/initializeTizen";
import { InitializeOrsay } from "./samsung/orsay/initializeOrsay";
import { InitializePhilips } from "./philips/initializePhilips";
import { InitializeSTB } from "./stb/initializeSTB";
import { InitializeGoogleTV } from "./googleTV/initializeGoogleTV";
import focus from "../../utils/focus";
import { SESSION } from "../../utils/session";
import { setAppVisibility } from "../../actions/uiActions";
import { TV_MODELS, VISIBILITY } from "../../utils/constants";

const InitializeAPI = () => {
    const dispatch = useDispatch();

    console.log("inicializo API");
    const model = useSelector((state) => state.device.model);

    document.addEventListener("visibilitychange", function () {
        if (document.hidden) {
            console.log("INTERFACE VISIBILITY CHANGE TO HIDDEN");
            dispatch(setAppVisibility(VISIBILITY.HIDDEN));
        } else {
            console.log("INTERFACE VISIBILITY CHANGE TO SHOWNf");
            dispatch(setAppVisibility(VISIBILITY.VISIBLE));
            if (focus?.value?.current != document.activeElement?.id) {
                document.getElementById(focus?.value?.current)?.focus();
            }
        }
    });

    switch (model) {
        case TV_MODELS.TIZEN:
            InitializeTizen();
            break;
        case TV_MODELS.ORSAY:
            InitializeOrsay();
            break;
        case TV_MODELS.LG:
            InitializeProcentric();
            break;
        case TV_MODELS.PHILIPS:
            InitializePhilips();
            break;
        case TV_MODELS.STB:
            InitializeSTB();
            break;
        case TV_MODELS.GOOGLE_TV:
            InitializeGoogleTV();
            break;
        default:
            InitializePC();
            break;
    }
};

export default InitializeAPI;
