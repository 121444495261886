import { useEffect } from "preact/hooks";
import Button from "../common/button";
import focus from "../../utils/focus";
import { Texts } from "../../utils/texts";
import { useDispatch, useSelector } from "react-redux";
import { displayErrorPopUp, setPopUp } from "../../actions/uiActions";
import Modal from "../common/modal";
import { useRef, useState } from "react";
import Calculator from "../common/calculator";
import { setNumpadCode } from "../../actions/eventsActions";
import { eventHandler } from "../../utils/eventHandler";
import { KEYS } from "../../utils/keys";
import { Theme } from "../../utils/theme";
import Query from "../../hooks/query";
import { inPreview } from "../../utils/utils";

const ParentalCode = () => {
    const dispatch = useDispatch();

    const texts = useSelector((state) => state.ui.texts);
    const numpadCode = useSelector((state) => state.events.numpadCode);
    const sessionData = useSelector((state) => state.status.sessionData);

    const cParentalCode = sessionStorage.getItem("parentalCode");

    const [step, setStep] = useState(cParentalCode ? "enabled" : "enable");
    const [error, setError] = useState("");

    const stepOrder = ["enable", "enterCode", "reenterCode", "enabled"];
    const firstCodeAttempt = useRef();
    const numpadCodeRef = useRef();
    const stepRef = useRef();
    const justDisabledRef = useRef();
    numpadCodeRef.current = numpadCode;
    stepRef.current = step;

    useEffect(() => {
        focus.value.replace(cParentalCode ? "btn-disable-code" : "btn-enable-code");
        cParentalCode &&
            setTimeout(function () {
                focus.value.replace("btn-disable-code");
            }, 100);
    }, []);

    useEffect(() => {
        switch (step) {
            case "enterCode":
            case "reenterCode":
                focus.value.replace("btn-1");
                break;
            case "enabled":
                focus.value.replace("btn-disable-code");
                break;

            default:
                break;
        }
    }, [step]);

    const getStepTitle = () => {
        let title = "parental code";
        switch (step) {
            case "enterCode":
                title = "enter-parental-code";
                break;
            case "reenterCode":
                title = cParentalCode ? "enter-code-to-confirm" : "reenter-parental-code";
                break;

            default:
                break;
        }
        return title;
    };

    const getStepBody = () => {
        let title = "parental code";
        switch (step) {
            case "enable":
                return getEnableContent();
            case "enterCode":
                return getEnterCodeContent();
            case "reenterCode":
                return getReenterCodeContent();
            case "enabled":
                return getEnabledContent();

            default:
                break;
        }
        return title;
    };
    const backAction = () => {
        switch (step) {
            case "enable":
            case "enabled":
                dispatch(setPopUp(null));
                break;
            case "enterCode":
                setStep("enable");
                break;
            case "reenterCode":
                if (cParentalCode) {
                    setStep("enabled");
                    return;
                }
                firstCodeAttempt.current = null;
                setStep("enterCode");
                break;
            default:
                break;
        }
    };

    const getEnableContent = () => {
        return (
            <>
                <div className={"mx-auto text-2xl text-center mt-6"} style={{ width: "28vw" }}>
                    {texts["parental-desc"]}
                </div>
                {justDisabledRef.current ? (
                    <div
                        className={"mx-auto text-2xl text-center mt-6"}
                        style={{
                            marginBottom: "10vh",
                            marginTop: "10vh",
                            color: Theme.colors.default.success,
                        }}
                    >
                        {Texts.capitalize(texts["code sucessfully disabled"])}
                    </div>
                ) : (
                    <div className={"mx-auto text-2xl text-center mt-6"} style={{ height: "25vh" }}></div>
                )}
                <Button
                    insideModal={true}
                    data={{
                        id: "enable-code",
                        name: Texts.capitalize(texts["enable code"]),
                        customClass: "text-center py-2 w-full text-2xl mb-8 rounded",
                        border: true,
                        onClick: function () {
                            setStep("enterCode");
                            justDisabledRef.current = false;
                        },
                    }}
                />
            </>
        );
    };
    const getEnabledContent = () => {
        return (
            <>
                <div className={"mx-auto text-2xl text-center mt-6"} style={{ width: "28vw" }}>
                    {texts["parental-desc"]}
                </div>
                <div
                    className={"mx-auto text-2xl text-center mt-6"}
                    style={{
                        width: "28vw",
                        marginBottom: "10vh",
                        marginTop: "10vh",
                        color: Theme.colors.default.success,
                    }}
                >
                    {Texts.capitalize(texts["enabled code"])} *****
                </div>
                <Button
                    insideModal={true}
                    data={{
                        id: "disable-code",
                        name: Texts.capitalize(texts["disable code"]),
                        customClass: "text-center py-2 w-full text-2xl mb-8 rounded",
                        border: true,
                        onClick: function () {
                            setStep("reenterCode");
                        },
                    }}
                    r
                />
            </>
        );
    };
    const getEnterCodeContent = () => {
        return (
            <>
                <Calculator
                    confirmText={Texts.capitalize(texts["continue"])}
                    digits={6}
                    minDigits={3}
                    encrypt={true}
                    confirmAction={function (newCode) {
                        if (!newCode) {
                            return;
                        }
                        firstCodeAttempt.current = newCode;
                        dispatch(setNumpadCode(null));
                        setStep("reenterCode");
                    }}
                    confirmBtnStyle={{ marginTop: "8vh" }}
                />
            </>
        );
    };
    const getReenterCodeContent = () => {
        return (
            <>
                <Calculator
                    confirmText={Texts.capitalize(texts["confirm"])}
                    code={cParentalCode || firstCodeAttempt.current}
                    encrypt={true}
                    confirmAction={function (newCode) {
                        if (newCode !== (cParentalCode || firstCodeAttempt.current)) {
                            setError(Texts.capitalize(texts["wrong parental code"]));
                        } else {
                            //disable parental code
                            if (cParentalCode) {
                                setParentalCode();
                                return;
                            }
                            setParentalCode(newCode);
                        }
                    }}
                    confirmBtnStyle={{ marginTop: "8vh" }}
                    error={error}
                    setError={setError}
                />
            </>
        );
    };

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;
        if (keyData) {
            switch (keyData.value) {
                case KEYS.back:
                    const cStep = stepOrder.indexOf(stepRef.current);
                    if (cStep > 0) {
                        if (stepRef.current === "enabled") {
                            dispatch(setPopUp(null));
                            return;
                        } else if (stepRef.current === "enterCode") {
                            firstCodeAttempt.current = null;
                        }
                        if (cParentalCode && stepRef.current === "reenterCode") {
                            setStep("enabled");
                        } else {
                            setStep(stepOrder[cStep - 1]);
                        }
                        preventDefault = true;
                    }
                    break;
                default:
                    break;
            }
        }
        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    const setParentalCode = (code) => {
        if (inPreview()) {
            dispatch(displayErrorPopUp({ text: texts["preview-msg"], timeout: 3000 }));
            return;
        }
        Query({
            query: `
            mutation{
                updateParentalCode(stayGuestRoomID:${
                    sessionData?.stayguest?.stayGuestRooms?.find(
                        (room) => parseInt(room.roomID) == parseInt(sessionData.room.id),
                    ).id
                } ${code ? `parentalCode: "${code}"` : ""}){
                id
                error{
                    code
                    msg
                }
                }
            }
            `,
            onResponse(res) {
                if (code && res?.data?.updateParentalCode?.id) {
                    sessionStorage.setItem("parentalCode", code);
                    setStep("enabled");
                } else {
                    sessionStorage.removeItem("parentalCode");
                    justDisabledRef.current = true;
                    setStep("enable");
                }
            },
        });
    };

    return (
        <div
            onKeyDown={(e) => {
                onkeydown(e);
            }}
            className={"fixed z-900"}
        >
            <Modal
                title={texts[getStepTitle()]}
                width={"35vw"}
                titleClass={"pt-6"}
                firstFocus={step === "enabled" ? "btn-exit" : "btn-enable-code"}
                backBtn={true}
                backText={step === "enabled" ? "close" : null}
                cancelBtn={["enterCode", "reenterCode"].includes(step)}
                preventCloseOnBack={["enterCode", "reenterCode"].includes(step)}
                backAction={() => backAction()}
                body={getStepBody()}
            />
        </div>
    );
};

export default ParentalCode;
