import { useEffect } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import {
    connectionError,
    setFontsReady,
    setInstalled,
    setLocationData,
    setServerOnline,
} from "../../../actions/statusActions";
import { showLoading } from "../../../actions/uiActions";
import { Theme } from "../../../utils/theme";
import { Media } from "../../apis/media";
import { STB } from "../../apis/stb";
import Query from "../../query";
import { DEVICE_FUNCTION, PERMISSION, ZAFIRO_MODES } from "../../../utils/constants";
import { SESSION } from "../../../utils/session";
import { EVENTS } from "../../../utils/eventsConst";
import { Logger } from "../../../utils/logger";

const IdentifyDevice = () => {
    const dispatch = useDispatch();

    const installed = useSelector((state) => state.status.installed);
    const texts = useSelector((state) => state.ui.texts);

    useEffect(() => {
        //Remove token to avoid wrong identifications
        const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.has("token") && searchParams.has("stayToken")) {
            showPreview(searchParams);
            return;
        }
        sessionStorage.removeItem("inPreview");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("tvAppOutdated");
        STB.checkOnLine();
        STB.getMac(checkDevice);
        Media.stop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showPreview = (searchParams) => {
        sessionStorage.setItem("inPreview", true);
        sessionStorage.setItem("token", searchParams.get("token"));
        sessionStorage.setItem("stayToken", searchParams.get("stayToken"));
        searchParams.has("lang") && localStorage.setItem("lang", searchParams.get("lang"));
        getLocationData();
    };

    //Check if this device is installed
    const checkDevice = () => {
        if (!installed) {
            Query({
                query: `
                    mutation{                        
                        identifyDevice (
                        deviceRef:"${STB.ref}" 
                        ${localStorage.getItem("lang") ? `language:"${localStorage.getItem("lang")}"` : ""}
                        ${
                            localStorage.getItem("firmwareVersion")
                                ? `firmwareVersion:"${localStorage.getItem("firmwareVersion")}"`
                                : ""
                        }
                        ${STB.ip ? `ip:"${STB.ip}"` : ""}
                        )
                        {
                            error 
                                {
                                    code 
                                    msg
                                } 
                            ok 
                            token
                            stayToken
                        }
                    }
              `,
                onResponse(res) {
                    if (res?.data?.identifyDevice?.ok) {
                        STB.renewToken(res.data.identifyDevice.token);
                        sessionStorage.setItem("token", res.data.identifyDevice.token);
                        sessionStorage.setItem("stayToken", res.data.identifyDevice.stayToken);
                        //If device is installed => get location information
                        getLocationData();
                    } else if (res?.data?.identifyDevice?.Error?.Code === 401) {
                        Theme.addThemeStyles(true);
                        dispatch(setServerOnline(true));
                        dispatch(setInstalled(false));
                        dispatch(showLoading(false));
                    } else {
                        throwConnectionError();
                    }
                },
            });
        }
    };
    const getLocationData = () => {
        Query({
            query: `
            {
                tvSession{
                    isOk
                    error
                    VODDomain
                    PMSActive
                    guestInfo{
                        id
                        title
                        name
                        surname
                        fullName
                        languageRef
                        checkoutDate
                        country
                    }
                    favouriteChannelIDs
                    rememberLastTvChannel
                    resetCredentialsHour
                    roomInfo{
                        name
                        code
                        number
                        roomTvId
                        roomTvName
                        chromecastTvInput
                        chromecastModel
                    }
                    wifiInfo{
                        ssid
                        password
                    }
                    tags{
                        tag
                        value
                    }
                    theme{
                        themeLogos{
                            imageRef
                            version
                            type
                        }
                        config{
                            colors{
                                defaultBackground
                                defaultForeground
                                focusBackground
                                focusForeground
                                activeBackground
                                activeForeground
                                focusBorderSize                                
                            }
                          
                            fontStyles{
                                heading{
                                    alignment
                                    bold
                                    font{
                                        externalUrl
                                        libraryRef
                                    }
                                    name
                                    italic
                                    size
                                    underline
                                }
                                paragraph{
                                    alignment
                                    bold
                                    font{
                                        externalUrl
                                        libraryRef
                                    }
                                    name
                                    italic
                                    size
                                    underline
                                }
                            }
                        }

                    }
                    pairingURL
                    mobileURL
                    chromecastName
                    chromecastRef
                    castingGatewayIP
                    airserverAccountCode
                    machineFunction
                    projectRef
                    projectName
                    projectCountryRef 
                    projectTimezone
                    chainName
                    permissions
                    projectCurrency
                    projectLangs {
                        languageRef
                        isDefault
                    }
                    highAvailabilityConfig{
                        alwaysDisplayTvChannels
                        messages{
                          connectionLost
                          connectionRestored
                        }
                    }
                    tvInputs{
                        deviceType
                        icon
                        imageRef
                        input
                        name
                        pos
                        tvInputID
                    }
                }
            }`,
            onResponse(res) {
                localStorage.setItem("currencyChar", "€"); //TODO: get info from server
                if (res?.data?.tvSession?.isOk) {
                    dispatch(setFontsReady(false));
                    if (!res.data.tvSession.projectCurrency) {
                        Logger.remoteLog("tvSession - projectCurrency no defined");
                        res.data.tvSession.projectCurrency = "EUR"; // default when not set
                    }
                    dispatch(setLocationData(res.data.tvSession));

                    STB.ensurePMSSession(res.data.tvSession?.guestInfo?.id);

                    dispatch(setServerOnline(true));
                    dispatch(setInstalled(true));
                    //apply theme
                    Theme.init(res.data.tvSession);

                    SESSION.init(res.data.tvSession);

                    // DATA FOR HIGH AVAILABILITY
                    SESSION.saveDataForHA(
                        {
                            [PERMISSION.HIGH_AVAILABILITY]: SESSION.hasPermission(PERMISSION.HIGH_AVAILABILITY),
                            connectionRdy: res?.data?.tvSession?.highAvailabilityConfig?.messages?.connectionRestored,
                            continueWatching: texts["Continue watching"],
                        },
                        texts,
                    );

                    if (res.data?.tvSession?.machineFunction == "TV+CAST") {
                        STB.initializeCastServices(
                            res.data?.tvSession?.castingGatewayIP,
                            res.data?.tvSession?.airserverAccountCode,
                        );
                    }
                    STB.changeTVName(
                        `${res?.data?.tvSession?.roomInfo?.name} , ${res?.data?.tvSession?.roomInfo?.roomTvName}`,
                    );
                    //Save VOD domain
                    Media.VODDomain = res.data.tvSession.VODDomain;

                    //Wait fonts to be ready to show app content
                    if (Theme.paragraph?.name) {
                        setTimeout(() => {
                            dispatch(setFontsReady(true));
                        }, 2000);
                        Theme.waitForWebfonts([Theme.paragraph.name], () => {
                            dispatch(setFontsReady(true));
                        });
                    } else {
                        dispatch(setFontsReady(true));
                    }
                } else {
                    Theme.addThemeStyles(true);
                    throwConnectionError();
                }
            },
        });
    };

    const throwConnectionError = () => {
        console.log("dispatched CONNECTION event");
        let e = new CustomEvent(EVENTS.type.CONNECTION, {
            detail: EVENTS.CONNECTION.ERROR,
        });
        document.dispatchEvent(e);
    };
};

export default IdentifyDevice;
