import { STB } from "../hooks/apis/stb";
import { STATE, STORAGE_KEY } from "./constants";
import { Logger } from "./logger";

export const checkResetCredentialsByHour = (_resetHour) => {
    if (!_resetHour) {
        return;
    }
    let formatOk = false;
    const reg = /^([01]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/;
    formatOk = reg.exec(_resetHour);

    //Check pending reset Credentials
    const lastReset = localStorage.getItem("lastResetCredentialsByHour");
    if (lastReset) {
        const oneDayinMs = 24 * 60 * 60 * 1000;
        const twoMinutesinMs = 2 * 60 * 1000;
        if (new Date().getTime() - new Date(lastReset).getTime() > oneDayinMs + twoMinutesinMs) {
            localStorage.setItem("lastResetCredentialsByHour", new Date().toISOString());
            STB.resetCredentials();
            return;
        }
    }

    // check time to reset Credentials
    if (formatOk && formatHour(new Date()) === _resetHour) {
        if (localStorage.getItem(STORAGE_KEY.POWER_STATE) == STATE.STANDBY) {
            localStorage.setItem("lastResetCredentialsByHour", new Date().toISOString());
            STB.resetCredentials();
        } else {
            // next power off reset credentials
            localStorage.setItem(STORAGE_KEY.RESET_CREDENTIALS, true);
        }
    }
};

function formatHour(_date) {
    return String(_date.getHours()).padStart(2, "0") + ":" + String(_date.getMinutes()).padStart(2, "0");
}
