import { STB } from "../hooks/apis/stb";
import { PERMISSION, STATE, TV_MODELS, ZAFIRO_MODES } from "./constants";
import { getChannelData } from "./utils";

export const SESSION = {
    networkStatus: STATE.ONLINE,
    permissions: {},
    machineFunction: "TV",
    projectDefaultLang: "en",
    cAudioTrackId: 0,
    //hourMode: HOUR_MODE[24],
    tvMode: ZAFIRO_MODES.FULL_INTERFACE,
    cMovie: { name: "" },
    init: function (tvSession) {
        this.tvSession = tvSession;
        this.tvMode =
            !tvSession?.guestInfo?.id && tvSession?.highAvailabilityConfig?.alwaysDisplayTvChannels
                ? ZAFIRO_MODES.TV
                : ZAFIRO_MODES.FULL_INTERFACE;
        this.guestCountry = tvSession?.guestInfo?.country;

        this.projectDefaultLang = tvSession.projectLangs?.find((x) => x.isDefault === true)?.languageRef || "en";
        localStorage.setItem("projectDefaultLang", this.projectDefaultLang);

        this._composePermissions(tvSession?.permissions);
        //this._setHourMode();
    },
    _composePermissions: function (permissions) {
        permissions.forEach((permissionsKey) => {
            this.permissions[permissionsKey] = true;
        });
    },
    // _setHourMode: function () {
    //     if (typeof Intl.DateTimeFormat("en-EN")?.formatToParts != "undefined") {
    //         const hourParts = new Intl.DateTimeFormat(
    //             `${localStorage.getItem("lang") || "en"}-${this.guestCountry || "XX"}`,
    //             {
    //                 hour: "numeric",
    //             },
    //         )?.formatToParts(new Date(2020, 0, 1, 13));
    //         if (!hourParts) {
    //             return;
    //         }
    //         console.log(hourParts);
    //         this.hourMode = hourParts.length > 2 ? HOUR_MODE[12] : HOUR_MODE[24];
    //         console.log(this.hourMode);
    //     }
    // },
    isTVOnCheckout: function () {
        return this.tvMode === ZAFIRO_MODES.TV;
    },
    hasPermission: function (permissionSeek) {
        return this?.permissions?.[permissionSeek] ?? false;
    },
    checkConnection: function () {
        if (!this.hasPermission(PERMISSION.HIGH_AVAILABILITY) && this.networkStatus === STATE.OFFLINE) {
            STB.gotoOfflineMode();
        }
    },
    ensureHAGrid: function (texts) {
        if (localStorage.getItem("haGridEnsuredDate")) {
            // remove localstorage flags when time HA grid ensured is more than
            const dateHaGridEnsured = new Date(localStorage.getItem("haGridEnsuredDate"));
            const twoWeeks = 1000 * 60 * 60 * 24 * 7;
            if (new Date().getTime() - dateHaGridEnsured.getTime() > twoWeeks) {
                localStorage.removeItem("haGridEnsured");
                localStorage.removeItem("haGridEnsuredDate");
            }
        }
        if (this.hasPermission(PERMISSION.HIGH_AVAILABILITY) && !localStorage.getItem("haGridEnsured")) {
            getChannelData(
                function () {
                    localStorage.setItem("haGridEnsured", true);
                    localStorage.setItem("haGridEnsuredDate", new Date());
                    if ([TV_MODELS.PHILIPS, TV_MODELS.PHILIPS_NO_CC].includes(STB.model)) {
                        STB.gotoOfflineMode();
                    } else if (STB.model === TV_MODELS.LG) {
                        STB.reload();
                    }
                },
                { texts: texts },
            );
        }
    },
    saveDataForHA: function (params, texts) {
        Object.keys(params).forEach((key) => {
            STB.saveDataForHA(key, params[key]);
        });
        this.ensureHAGrid(texts);
    },
    getChromecastInput: function () {
        const cccInput = this.tvSession?.tvInputs?.find((input) => input.deviceType === "CHROMECAST");
        if (cccInput) {
            return parseInt(cccInput.input?.slice(cccInput.input.length - 1, cccInput.input.length));
        }

        return this.tvSession?.roomInfo?.chromecastTvInput;
    },
    getChromecastRef: function () {
        return this.tvSession?.chromecastRef;
    },
};
